function CallToAction() {
  return (
    <section className="py-16 bg-primary">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="text-center">
          <h2 className="text-3xl font-heading font-extrabold text-white sm:text-4xl">
            Ready to Transform Your Energy Management?
          </h2>
          <p className="mt-4 text-xl text-white/80">
            Join the movement towards sustainable tidal energy with Zephyrine.
          </p>
          <div className="mt-8">
            <button className="bg-white text-primary px-8 py-3 rounded-custom hover:bg-accent/10 transition-colors shadow-custom">
              Get Started Today
            </button>
          </div>
        </div>
      </div>
    </section>
  )
}

export default CallToAction 