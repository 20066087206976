function Navbar() {
  return (
    <nav className="fixed top-0 w-full bg-background/80 backdrop-blur-sm z-50 shadow-custom-sm">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex justify-between h-16 items-center">
          <div className="flex-shrink-0">
            <span className="text-2xl font-heading font-bold text-primary">Zephyrine</span>
          </div>
          
          <div className="hidden md:block">
            <div className="ml-10 flex items-center space-x-4">
              <a href="#" className="text-textColor hover:text-primary px-3 py-2 transition-colors">Home</a>
              <a href="#" className="text-textColor hover:text-primary px-3 py-2 transition-colors">About</a>
              <a href="#" className="text-textColor hover:text-primary px-3 py-2 transition-colors">Services</a>
              <a href="#" className="text-textColor hover:text-primary px-3 py-2 transition-colors">Contact</a>
              <button className="bg-primary text-white px-4 py-2 rounded-custom hover:bg-secondary transition-colors shadow-custom">
                Get Started
              </button>
            </div>
          </div>
        </div>
      </div>
    </nav>
  )
}

export default Navbar 