function Features() {
  const features = [
    {
      title: "AI-Powered Drones",
      description: "State-of-the-art sensors for precise data collection",
      icon: "🚁"
    },
    {
      title: "Real-Time Data",
      description: "Access live data on tidal patterns and energy output",
      icon: "📊"
    },
    {
      title: "Predictive Analytics",
      description: "Forecast future trends to optimize energy use",
      icon: "📈"
    }
  ]

  return (
    <section className="py-16 bg-background">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="text-center">
          <h2 className="text-3xl font-heading font-extrabold text-textColor sm:text-4xl">
            Key Features
          </h2>
          <p className="mt-4 max-w-2xl mx-auto text-xl text-textColor/80">
            Discover how Zephyrine's intelligent system transforms tidal energy management.
          </p>
        </div>

        <div className="mt-16">
          <div className="grid grid-cols-1 gap-8 sm:grid-cols-2 lg:grid-cols-3">
            {features.map((feature, index) => (
              <div
                key={index}
                className="relative p-6 bg-white rounded-custom shadow-custom hover:shadow-lg transition-all hover:bg-primary/5"
              >
                <div className="text-4xl mb-4">{feature.icon}</div>
                <h3 className="text-xl font-heading font-bold text-textColor">{feature.title}</h3>
                <p className="mt-2 text-textColor/80">{feature.description}</p>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  )
}

export default Features 