function Introduction() {
  return (
    <section className="py-16 bg-white shadow-custom-sm">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="text-center">
          <h2 className="text-3xl font-heading font-extrabold text-textColor sm:text-4xl">
            Empowering Coastal Communities
          </h2>
          <p className="mt-4 max-w-2xl mx-auto text-xl text-textColor/80">
            In the face of climate change and growing energy demands, harnessing the power of the tides has never been more crucial. Zephyrine offers a revolutionary approach to tidal energy monitoring, empowering coastal communities with the tools they need to thrive sustainably.
          </p>
        </div>
      </div>
    </section>
  )
}

export default Introduction 