import Navbar from './components/Navbar'
import Hero from './components/Hero'
import Introduction from './components/Introduction'
import Features from './components/Features'
import Benefits from './components/Benefits'
import StartupJourney from './components/StartupJourney'
import CallToAction from './components/CallToAction'
import Footer from './components/Footer'

function App() {
  return (
    <div className="min-h-screen bg-gradient-to-b from-blue-50 to-white">
      <Navbar />
      <main>
        <Hero />
        <Introduction />
        <Features />
        <Benefits />
        <StartupJourney />
        <CallToAction />
      </main>
      <Footer />
    </div>
  )
}

export default App 