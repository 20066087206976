function Footer() {
  const contactInfo = {
    companyName: "ZEPHYR WIND SL",
    nif: "B85218972",
    address: "Paseo de la Castellana, 53, 28046 Madrid, Madrid, España",
    phone: "+34914420022",
    email: "contact@zephyrineai.com"
  }

  const socialLinks = [
    {
      name: 'LinkedIn',
      href: '#',
      icon: (props) => (
        <svg fill="currentColor" viewBox="0 0 24 24" {...props}>
          <path d="M19 0h-14c-2.761 0-5 2.239-5 5v14c0 2.761 2.239 5 5 5h14c2.762 0 5-2.239 5-5v-14c0-2.761-2.238-5-5-5zm-11 19h-3v-11h3v11zm-1.5-12.268c-.966 0-1.75-.79-1.75-1.764s.784-1.764 1.75-1.764 1.75.79 1.75 1.764-.783 1.764-1.75 1.764zm13.5 12.268h-3v-5.604c0-3.368-4-3.113-4 0v5.604h-3v-11h3v1.765c1.396-2.586 7-2.777 7 2.476v6.759z"/>
        </svg>
      )
    },
    {
      name: 'Twitter',
      href: '#',
      icon: (props) => (
        <svg fill="currentColor" viewBox="0 0 24 24" {...props}>
          <path d="M23.953 4.57a10 10 0 01-2.825.775 4.958 4.958 0 002.163-2.723c-.951.555-2.005.959-3.127 1.184a4.92 4.92 0 00-8.384 4.482C7.69 8.095 4.067 6.13 1.64 3.162a4.822 4.822 0 00-.666 2.475c0 1.71.87 3.213 2.188 4.096a4.904 4.904 0 01-2.228-.616v.06a4.923 4.923 0 003.946 4.827 4.996 4.996 0 01-2.212.085 4.936 4.936 0 004.604 3.417 9.867 9.867 0 01-6.102 2.105c-.39 0-.779-.023-1.17-.067a13.995 13.995 0 007.557 2.209c9.053 0 13.998-7.496 13.998-13.985 0-.21 0-.42-.015-.63A9.935 9.935 0 0024 4.59z" />
        </svg>
      )
    }
  ]

  return (
    <footer className="bg-secondary">
      <div className="max-w-7xl mx-auto py-12 px-4 sm:px-6 lg:px-8">
        <div className="mb-12 pb-10 border-b border-white/10">
          <div className="max-w-xl mx-auto text-center">
            <h3 className="text-2xl font-heading font-bold text-white mb-4">
              Subscribe to Our Newsletter
            </h3>
            <p className="text-white/60 mb-6">
              Stay updated with the latest developments in tidal energy and sustainability
            </p>
            <form className="flex flex-col sm:flex-row gap-3 max-w-md mx-auto">
              <input
                type="email"
                placeholder="Enter your email"
                className="flex-1 px-4 py-2 rounded-custom bg-white/10 border border-white/20 text-white placeholder-white/40 focus:outline-none focus:border-accent"
              />
              <button
                type="submit"
                className="bg-accent text-white px-6 py-2 rounded-custom hover:bg-accent/90 transition-colors shadow-custom"
              >
                Subscribe
              </button>
            </form>
          </div>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-4 gap-8">
          <div>
            <h3 className="text-white text-lg font-heading font-semibold mb-4">Contact Us</h3>
            <ul className="space-y-3">
              <li className="text-white/60">
                <span className="block font-heading text-white">{contactInfo.companyName}</span>
                <span className="block text-sm">NIF: {contactInfo.nif}</span>
              </li>
              <li className="text-white/60 text-sm">
                <div className="flex items-start space-x-2">
                  <svg className="w-5 h-5 mt-0.5 text-accent" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z" />
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 11a3 3 0 11-6 0 3 3 0 016 0z" />
                  </svg>
                  <span>{contactInfo.address}</span>
                </div>
              </li>
              <li className="text-white/60">
                <a href={`tel:${contactInfo.phone}`} className="flex items-center space-x-2 hover:text-accent transition-colors">
                  <svg className="w-5 h-5 text-accent" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M3 5a2 2 0 012-2h3.28a1 1 0 01.948.684l1.498 4.493a1 1 0 01-.502 1.21l-2.257 1.13a11.042 11.042 0 005.516 5.516l1.13-2.257a1 1 0 011.21-.502l4.493 1.498a1 1 0 01.684.949V19a2 2 0 01-2 2h-1C9.716 21 3 14.284 3 6V5z" />
                  </svg>
                  <span>{contactInfo.phone}</span>
                </a>
              </li>
              <li className="text-white/60">
                <a href={`mailto:${contactInfo.email}`} className="flex items-center space-x-2 hover:text-accent transition-colors">
                  <svg className="w-5 h-5 text-accent" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z" />
                  </svg>
                  <span>{contactInfo.email}</span>
                </a>
              </li>
            </ul>
          </div>
          <div>
            <h3 className="text-white text-lg font-heading font-semibold mb-4">About Us</h3>
            <ul className="space-y-2">
              <li><a href="#" className="text-white/60 hover:text-accent transition-colors">Our Mission</a></li>
              <li><a href="#" className="text-white/60 hover:text-accent transition-colors">Team</a></li>
              <li><a href="#" className="text-white/60 hover:text-accent transition-colors">Careers</a></li>
            </ul>
          </div>
          <div>
            <h3 className="text-white text-lg font-heading font-semibold mb-4">Resources</h3>
            <ul className="space-y-2">
              <li><a href="#" className="text-white/60 hover:text-accent transition-colors">Blog</a></li>
              <li><a href="#" className="text-white/60 hover:text-accent transition-colors">Case Studies</a></li>
              <li><a href="#" className="text-white/60 hover:text-accent transition-colors">Support</a></li>
            </ul>
          </div>
          <div>
            <h3 className="text-white text-lg font-heading font-semibold mb-4">Legal</h3>
            <ul className="space-y-2">
              <li><a href="#" className="text-white/60 hover:text-accent transition-colors">Privacy Policy</a></li>
              <li><a href="#" className="text-white/60 hover:text-accent transition-colors">Terms of Service</a></li>
            </ul>
          </div>
        </div>

        <div className="mt-8 pt-8 border-t border-white/10">
          <div className="flex justify-center space-x-6 mb-8">
            {socialLinks.map((item) => (
              <a
                key={item.name}
                href={item.href}
                className="text-white/60 hover:text-accent transition-colors"
              >
                <span className="sr-only">{item.name}</span>
                <item.icon className="h-6 w-6" aria-hidden="true" />
              </a>
            ))}
          </div>
          <p className="text-white/60 text-center">
            © 2024 Zephyrine. All rights reserved.
          </p>
          <p className="text-white/40 text-center text-sm mt-2">
            Sustainable energy solutions for a better tomorrow
          </p>
        </div>
      </div>
    </footer>
  )
}

export default Footer 