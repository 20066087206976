function Benefits() {
  const benefits = [
    {
      title: "Optimize Energy Use",
      description: "Maximize tidal energy efficiency with real-time monitoring and AI-powered optimization",
      icon: (
        <svg className="w-8 h-8" fill="none" stroke="currentColor" viewBox="0 0 24 24">
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M13 10V3L4 14h7v7l9-11h-7z" />
        </svg>
      )
    },
    {
      title: "Protect Marine Life",
      description: "Advanced sensors and monitoring systems ensure minimal impact on marine ecosystems",
      icon: (
        <svg className="w-8 h-8" fill="none" stroke="currentColor" viewBox="0 0 24 24">
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M7 12l3-3 3 3 4-4M8 21l4-4 4 4M3 4h18M4 4h16v12a1 1 0 01-1 1H5a1 1 0 01-1-1V4z" />
        </svg>
      )
    },
    {
      title: "Sustainable Future",
      description: "Contributing to a greener tomorrow with renewable tidal energy solutions",
      icon: (
        <svg className="w-8 h-8" fill="none" stroke="currentColor" viewBox="0 0 24 24">
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M3.055 11H5a2 2 0 012 2v1a2 2 0 002 2 2 2 0 012 2v2.945M8 3.935V5.5A2.5 2.5 0 0010.5 8h.5a2 2 0 012 2 2 2 0 104 0 2 2 0 012-2h1.064M15 20.488V18a2 2 0 012-2h3.064M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
        </svg>
      )
    }
  ]

  return (
    <section className="py-20 bg-gradient-to-b from-background to-white">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="text-center max-w-3xl mx-auto mb-16">
          <h2 className="text-3xl font-heading font-extrabold text-textColor sm:text-4xl">
            Benefits of Choosing Zephyrine
          </h2>
          <p className="mt-4 text-xl text-textColor/80">
            Join us in pioneering a greener future with innovative tidal energy solutions
          </p>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-3 gap-12">
          {benefits.map((benefit, index) => (
            <div 
              key={index}
              className="relative group"
            >
              <div className="absolute -inset-1 bg-gradient-to-r from-primary to-secondary rounded-custom blur opacity-25 group-hover:opacity-40 transition duration-200"></div>
              <div className="relative bg-white p-8 rounded-custom shadow-custom group-hover:shadow-lg transition duration-200">
                <div className="flex flex-col items-center text-center space-y-4">
                  <div className="p-3 bg-primary/5 rounded-full text-primary">
                    {benefit.icon}
                  </div>
                  <h3 className="text-xl font-heading font-bold text-textColor">
                    {benefit.title}
                  </h3>
                  <p className="text-textColor/80">
                    {benefit.description}
                  </p>
                </div>
                <div className="mt-6 flex justify-center">
                  <a 
                    href="#" 
                    className="inline-flex items-center text-primary hover:text-secondary transition-colors"
                  >
                    <span className="font-heading font-semibold">Learn more</span>
                    <svg 
                      className="ml-2 w-4 h-4" 
                      fill="none" 
                      stroke="currentColor" 
                      viewBox="0 0 24 24"
                    >
                      <path 
                        strokeLinecap="round" 
                        strokeLinejoin="round" 
                        strokeWidth={2} 
                        d="M9 5l7 7-7 7"
                      />
                    </svg>
                  </a>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  )
}

export default Benefits 