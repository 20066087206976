function StartupJourney() {
  const milestones = [
    {
      number: "121,408",
      label: "Project Been Reports",
      description: "The total number of coastal energy monitoring reports generated"
    },
    {
      number: "Features",
      label: "Extending up 3 the infinity",
      description: "Empowering coastal communities for a sustainable future"
    },
    {
      number: "$800",
      label: "Per Earning Per Day",
      description: "Average energy cost savings for partner communities"
    },
    {
      number: "$100,004",
      label: "Run The Growth Analytics",
      description: "Investment in sustainable tidal energy research"
    }
  ]

  return (
    <section className="py-16 bg-background">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="text-center mb-12">
          <h2 className="text-3xl font-heading font-extrabold text-textColor sm:text-4xl">
            Our Startup Journey
          </h2>
          <p className="mt-4 max-w-2xl mx-auto text-xl text-textColor/80">
            Tracking our impact on coastal communities and sustainable energy
          </p>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
          {milestones.map((milestone, index) => (
            <div 
              key={index}
              className="bg-white p-6 rounded-custom shadow-custom hover:shadow-lg transition-all hover:bg-primary/5"
            >
              <div className="flex flex-col items-center text-center">
                <span className="text-3xl font-heading font-bold text-primary mb-2">
                  {milestone.number}
                </span>
                <h3 className="text-lg font-heading font-semibold text-textColor mb-2">
                  {milestone.label}
                </h3>
                <p className="text-textColor/80">
                  {milestone.description}
                </p>
              </div>
            </div>
          ))}
        </div>

        <div className="mt-12 text-center">
          <div className="inline-flex items-center space-x-2 text-primary hover:text-secondary transition-colors cursor-pointer">
            <span className="font-heading font-semibold">Learn more about our journey</span>
            <svg 
              className="w-5 h-5" 
              fill="none" 
              stroke="currentColor" 
              viewBox="0 0 24 24"
            >
              <path 
                strokeLinecap="round" 
                strokeLinejoin="round" 
                strokeWidth={2} 
                d="M17 8l4 4m0 0l-4 4m4-4H3" 
              />
            </svg>
          </div>
        </div>
      </div>
    </section>
  )
}

export default StartupJourney 