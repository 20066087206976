function Hero() {
  return (
    <div className="relative pt-16 pb-32 overflow-hidden bg-background">
      <div className="mt-24">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="text-center">
            <div>
              <h1 className="font-heading text-4xl tracking-tight font-extrabold text-textColor sm:text-5xl md:text-6xl lg:text-5xl xl:text-6xl">
                <span className="block">Harness the Power</span>
                <span className="block text-primary">of the Tides</span>
              </h1>
              <p className="mt-3 text-base text-textColor/80 sm:mt-5 sm:text-xl lg:text-lg xl:text-xl">
                Revolutionizing tidal energy monitoring for a sustainable future.
              </p>
              <div className="mt-8 sm:mt-12">
                <div className="space-x-4">
                  <button className="bg-primary text-white px-8 py-3 rounded-custom hover:bg-secondary transition-colors shadow-custom">
                    Learn More
                  </button>
                  <button className="bg-background text-textColor px-8 py-3 rounded-custom hover:bg-accent/10 transition-colors shadow-custom-sm border border-secondary/20">
                    Get Started
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Hero 